import { useCallback, useState } from "react"

import useDebounce from "hooks/useDebounce"

/**
 * How long to wait after a key press until the search
 * is executed.
 */
const SEARCH_DEBOUNCE_DELAY_MS = 100

export interface SearchState {
  term: string | null
  setTerm: (term: string | null) => void
  isActive: boolean
  debouncedTerm: string | null
  start: () => void
  cancel: () => void
  clear: () => void
  handleBlur: () => void
}

export const useSearchState = (initial: string | null = null): SearchState => {
  const [term, setTerm] = useState<string | null>(initial)
  const [isActive, setIsActive] = useState(false)

  const debouncedTerm = useDebounce(term, SEARCH_DEBOUNCE_DELAY_MS)

  const cancel = useCallback(() => {
    setTerm("")
    setIsActive(false)
  }, [])

  const start = () => setIsActive(true)
  const clear = () => setTerm("")

  const handleBlur = () => {
    const shouldCancel = isActive && !term
    if (shouldCancel) {
      cancel()
    }
  }

  return {
    term,
    setTerm,
    isActive,
    debouncedTerm,
    start,
    cancel,
    clear,
    handleBlur,
  }
}
